<template>
  <div v-if="isPermission">
      <select-shop :loadingButton="loadingButton" v-on:getData="getStock"></select-shop>
      <CCard>
        <CCardBody>
          <div class="row">
            <div class="col-md-10 col-sm-9 col-9 d-flex align-items-start">
              <div class="font-weight-normal">
                <h2 v-show="transferIn" id="transferInTitle" class="font-weight-bold">
                  {{ $t("management.transferStockInDoc") }}
                </h2>
                <h2 v-show="transferOut" id="transferOutTitle" class="font-weight-bold">
                  {{ $t("management.transferStockDoc") }}
                </h2>
                <p id="transferMoreInfo" class="mt-2">{{ $t("moreInfo") }} <i class="fi fi-rr-info ml-1" id="transferHelpIcon" @click="openHelpModal = true"></i></p>
              </div>
              <HelpButton :isHeader="false" class="ml-2 mt-2"></HelpButton>
            </div>
            <div id="transferHeaderRight" v-show="transferOut" class="col-md-2 col-sm-3 col-3 text-right">
              <CButton id="transferCreateButton" v-if="isEditData" to="/inventory/transfer/createTransfer" block color="warning" style="color: white;">
                <i class="fas fa-plus"></i> {{ $t("createTransferDoc") }}
              </CButton>
            </div>
          </div>
          <CRow>
            <CCol sm="12" lg="12">
              <hr />
              <CRow>
                <CCol class="col-md-2 col-sm-2 col-3 mb-3">
                  <select id="transferStatusSelect"class="custom-select" v-model="statusDoc">
                    <option id="transferStatusSelectAll" value="" selected>{{ $t("allDoc") }}</option>
                    <option id="transferStatusASelect" value="A">{{ $t("successDoc") }}</option>
                    <option id="transferStatusPSelect" value="P">{{ $t("pendingDoc") }}</option>
                    <option id="transferStatusCSelect" value="C">{{ $t("cancelDoc") }}</option>
                  </select>
                </CCol>
                <CCol id="transferSearchInput" class="col-md-8 col-sm-8 col-6">
                  <div style="margin-left: 2px">
                    <CInput v-model.trim="keyword" :placeholder="$t('searchTransfer')" />
                  </div>
                </CCol>
                <CCol id="transferSearchButton" class="col-md-2 col-sm-2 col-3" v-if="searchLoadingButton === true">
                  <CButton v-on:click="getStock()" block color="success">
                    {{ $t("search") }}
                  </CButton>
                </CCol>
                <CCol id="transferSearchSpinner" lg="2" md="2" sm="2" v-else-if="searchLoadingButton === false">
                  <CButton block color="success" disabled>
                    <CSpinner color="white" size="sm" />
                  </CButton>
                </CCol>
              </CRow>

              <CDataTable
                id="transferDataTable"
                :items="items"
                :fields="fields"
                :loading="loading"
                hover
                border
                clickableRows
                @row-clicked="transferOutDetail"
                style="width: 100%; white-space: nowrap"
              >
                <template #date="{ item, index }">
                  <td :id="'transferDate' + index" class="text-left">
                    <p v-if="item.status == 'C'" class="text-danger" style="margin: 0%">
                      {{ item.date }}
                    </p>
                    <p v-else class="text-dark" style="margin: 0%">
                      {{ item.date }}
                    </p>
                  </td>
                </template>
                <template #sourceBranch="{ item, index }">
                  <td :id="'transferSourceBranch' + index" class="text-left">
                    <p v-if="item.status == 'C'" class="text-danger" style="margin: 0%">
                      {{ item.sourceBranch }}
                    </p>
                    <p v-else class="text-dark" style="margin: 0%">
                      {{ item.sourceBranch }}
                    </p>
                  </td>
                </template>
                <template #siteBranch="{ item, index }">
                  <td :id="'transferSiteBranch' + index" class="text-left">
                    <p v-if="item.status == 'C'" class="text-danger" style="margin: 0%">
                      {{ item.siteBranch }}
                    </p>
                    <p v-else class="text-dark" style="margin: 0%">
                      {{ item.siteBranch }}
                    </p>
                  </td>
                </template>
                <template #username="{ item, index }">
                  <td :id="'transferUsername' + index" class="text-left">
                    <p v-if="item.status == 'C'" class="text-danger" style="margin: 0%">
                      {{ item.username }}
                    </p>
                    <p v-else class="text-dark" style="margin: 0%">
                      {{ item.username }}
                    </p>
                  </td>
                </template>
                <template #stockNo="{ item, index }">
                  <td :id="'transferStockNo' + index" class="text-left">
                    <p :class="[checkStatus(item)]" style="margin: 0%">
                      {{ item.stockNo }}
                    </p>
                  </td>
                </template>
                <template #refDocument="{ item, index }">
                  <td :id="'transferRefDocument' + index" class="text-left">
                    <p :class="[checkStatus(item)]" style="margin: 0%">
                      {{ item.refDocument }}
                    </p>
                  </td>
                </template>
                <template #status="{ item, index }">
                  <td :id="'transferStatus' + index" class="text-left">
                    <p v-if="item.status == 'P'" :class="[checkStatus(item)]" style="margin: 0%">
                      {{ $t("pending") }}
                    </p>
                    <p
                      v-else-if="item.status == 'A'"
                      class="text-success"
                      style="margin: 0%"
                    >
                      {{ $t("finish") }}
                    </p>
                    <p
                      v-else-if="item.status == 'C'"
                      class="text-danger"
                      style="margin: 0%"
                    >
                      {{ $t("cancel") }}
                    </p>
                    <CProgress id="transferProgress" v-show="item.status == 'A'">
                      <CProgressBar
                        :value="calculateProgress(item)"
                        color="success"
                        :max="100"
                      />
                    </CProgress>
                    <small id="transferQtyReceived" v-show="item.status == 'A'">
                      {{ item.totalitemQTYReceived }} / {{ item.totalitemquantity }}
                      {{ $t("items") }}
                    </small>
                    <br v-if="isOverDue(item)">
                    <small v-if="isOverDue(item)" class="text-orange">
                      {{ $t('overDueDate') }}
                    </small>
                    <small id="transferDuedate" :class="getDueDateClass(item)" v-if="item.status == 'P'">
                      {{ $t('dueDate') }} : {{ formatDate(item.dueDate) }}
                    </small>
                  </td>
                </template>
                <template #no-items-view>
                  <div class="text-center my-5">
                    <h4 style="color: #ced2d8">
                      {{ $t("noItem") }}
                    </h4>
                  </div>
                </template>
              </CDataTable>
            </CCol>
          </CRow>
          <pagination
            id="transferPagination"
            :meta_data="meta_data"
            v-on:next="getStock"
          >
          </pagination>
        </CCardBody>
      </CCard>
    <CModal
      id="transferHelpModal"
      :show.sync="openHelpModal"
      centered
      :title="$t('userManual')"
      color="success"
      header="false"
      size="lg"
    >
      <div class="col-12 p-4" v-show="transferIn" >
        <h4 class="text-center">{{ $t("transferInHelpTitle") }}</h4>
        <p class="h5">
          <img id="transferInHelpImage" src="/img/helpTransferOut.jpg" class="img-fluid p-2 mb-2" />
            {{ $t('transferInHelpintro') }}:
            <ol>
              <li>{{ $t('transferInstep1') }}</li>
              <li>{{ $t('transferInstep2') }}</li>
              <li>{{ $t('transferInstep3') }}</li>
              <li>{{ $t('transferInstep4') }}</li>
            </ol>
            <div id="transferInNote" class="text-danger">**{{ $t('transferInnote') }}</div>
          </p>
      </div>
      <div class="col-12 p-4" v-show="transferOut" >
        <h4 class="text-center">{{ $t("transferOutGuideTitle") }}</h4>
        <p class="h5">
          <div class="d-flex justify-content-center">
            <img id="transferOutHelpImage" src="/img/helpTransferIn.jpg" class="img-fluid p-2 mb-2" />
          </div>            
          <div class="text-danger" style="font-weight:1000; font-size: 18px;">*{{ $t('การโอนสินค้าระหว่างต้องเป็นสาขาที่สร้างจากอีเมลเดียวกันเท่านั้น ไม่สามารถใช้ร่วมกับสาขาที่ใช้ต่างอีเมล') }} </div>
            <div class="" style="font-weight:1000; font-size: 18px;">{{ $t('transferOutMethodsIntro') }} :</div>
            <div  style="font-weight:1000; font-size: 18px;">{{ $t('transferOutStepsTitle') }} </div>
            <ol style="font-size: 16px;">
              <li>{{ $t('transferOutStep1') }}</li>
              <li>{{ $t('transferOutStep2') }}</li>
              <li>{{ $t('transferOutStep3') }}</li>
              <li>{{ $t('transferOutStep4') }}</li>
              <li>{{ $t('transferOutStep5') }}</li>
              <li>{{ $t('transferOutStep6') }}</li>
              <li>{{ $t('transferOutStep7') }}</li>
            </ol>
            <p id="transferOutNote" class="h5 mt-3 text-danger" style="font-weight: 900;">**{{ $t('transferOutNoteTitle') }}</p>
            <ul>
              <li>{{ $t('transferOutNote1') }}</li>
              <li>{{ $t('transferOutNote2') }}</li>
              <li>{{ $t('transferOutNote3') }}</li>
              <li>{{ $t('transferOutNote4') }}</li>
            </ul>
          </p>
      </div>
      <template #footer>
        <CRow class="justify-content-around col-md-12">
          <CCol col="4" >
            <CButton id="transferHelpSubmit" @click="openHelpModal = false" color="outline-success"  block>
              <b>{{ $t("submit") }}</b>
            </CButton>
          </CCol>
        </CRow>
      </template>
    </CModal>
  </div>
  <div v-else>
      <access-data></access-data>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import moment, { duration } from "moment";
import axios from "@/services/local";
import Pagination from "../../../containers/Pagination";
import { CButton } from "@coreui/vue";
import permis from '@/util/permission'
import HelpButton from "../../../containers/HelpButton.vue";

export default {
  components: {
    Pagination,
    HelpButton
  },
  data() {
    return {
      data: [],
      meta_data: {
        last_page: null,
        current_page: 1,
        prev_page_url: null,
        items: 0,
        limit: null,
        itemCount: null,
      },
      loadingButton: true,
      typeDocument: "",
      datastatus: "",
      loading: false,
      transferIn: false,
      transferOut: false,
      statusDoc: "",
      searchLoadingButton: true,
      keyword: "",
      moment,
      openHelpModal: false
    };
  },
  computed: {
    ...mapGetters(["shops", "users", "date", 'permissions']),
    isPermission() {
      return permis.findPermissionRead('inventory', this.$route.path)
    },
    isEditData() {
      return permis.findPermissionEdit('inventory', this.$route.path)
    },
    shopObjectId: {
      get() {
        return this.$store.getters.shopObjectId;
      },
      set(newValue) {
        return this.$store.dispatch("setShop", newValue);
      },
    },
    startDate() {
      return moment(String(this.date.start)).format("DD MMMM YYYY");
    },
    endDate() {
      return moment(String(this.date.end)).format("DD MMMM YYYY");
    },
    fields() {
      if (this.transferIn) {
        return [
          {
            key: "date",
            label: this.$i18n.t("date"),
            _classes: "text-left width:15% text-dark font-weight-normal",
          },
          {
            key: "refDocument",
            label: this.$i18n.t("transferOutNo"),
            _classes: "text-left width:25% text-dark font-weight-normal",
          },
          {
            key: "stockNo",
            label: this.$i18n.t("transferInNo"),
            _classes: "text-left width:25% text-dark font-weight-normal",
          },
          {
            key: "siteBranch",
            label: this.$i18n.t("sourceBranch"),
            _classes: "text-left width:10% text-dark font-weight-normal",
          },
          {
            key: "sourceBranch",
            label: this.$i18n.t("destinationShopBranch"),
            _classes: "text-left width:10% text-dark font-weight-normal",
          },
          {
            key: "username",
            label: this.$i18n.t("username"),
            _classes: "text-left width:10% text-dark font-weight-normal",
          },
          {
            key: "status",
            label: this.$i18n.t("statusDoc"),
            _classes: "text-left width:25% text-dark font-weight-normal",
          },
        ];
      } else {
        return [
          {
            key: "date",
            label: this.$i18n.t("date"),
            _classes: "text-left width:15% text-dark font-weight-normal",
          },
          {
            key: "stockNo",
            label: this.$i18n.t("transferOutNo"),
            _classes: "text-left width:25% text-dark font-weight-normal",
          },
          {
            key: "refDocument",
            label: this.$i18n.t("transferInNo"),
            _classes: "text-left width:25% text-dark font-weight-normal",
          },
          {
            key: "sourceBranch",
            label: this.$i18n.t("sourceBranch"),
            _classes: "text-left width:10% text-dark font-weight-normal",
          },
          {
            key: "siteBranch",
            label: this.$i18n.t("destinationShopBranch"),
            _classes: "text-left width:10% text-dark font-weight-normal",
          },
          {
            key: "username",
            label: this.$i18n.t("username"),
            _classes: "text-left width:10% text-dark font-weight-normal",
          },
          {
            key: "status",
            label: this.$i18n.t("statusDoc"),
            _classes: "text-left width:25% text-dark font-weight-normal",
          },
        ];
      }
    },
    items() {
      let data = this.data;
      let detail = [];
      for (let i = 0; i < data.length; i++) {
        let stockNo = data[i].stockNo == null ? "-" : data[i].stockNo;
        let refDoc = "";
        if (data[i].refDocument && data[i].refDocument.stockNo) {
          refDoc = data[i].refDocument.stockNo;
        } else if (data[i].contractDocument && data[i].contractDocument.stockNo) {
          refDoc = data[i].contractDocument.stockNo;
        } else {
          refDoc = "-";
        }
        let received_at = data[i].received_at == null ? '-' : data[i].received_at
        let dueDate = data[i].dueDate == null ? '-' : data[i].dueDate
        let sourceBranch = data[i].shop == null ? "-" : data[i].shop.branchName;
        let siteBranch =
          data[i].site == null
            ? data[i].source == null
              ? "-"
              : data[i].source.branchName
            : data[i].site.branchName;
        let items = data[i].items === null ? this.$t("ไม่พบสินค้า") : data[i].items;

        let totalitemquantity = 0;
        let totalitemQTYReceived = 0;
        

        if (Array.isArray(items)) {
          for (let j = 0; j < items.length; j++) {
            totalitemquantity += parseFloat(items[j].quantity) || 0;
            totalitemQTYReceived += parseFloat(items[j].quantityReceived) || 0;
          }
        }
        if (data[i].status === "P" || data[i].status === "C") {
          totalitemQTYReceived = 0;
        }

        detail.push({
          date: moment(String(data[i].created_at))
            .tz("Asia/Bangkok")
            .format("DD/MM/YYYY HH:mm"),
          stockNo: stockNo,
          username: data[i].created_by.username,
          status: data[i].status,
          objectId: data[i].objectId,
          refDocument: refDoc,
          sourceBranch: sourceBranch,
          siteBranch: siteBranch,
          totalitemquantity: totalitemquantity,
          totalitemQTYReceived: totalitemQTYReceived,
          dueDate : moment(String(dueDate))
            .format("DD/MM/YYYY HH:mm:ss"),
            received_at : moment(String(received_at))
            .format("DD/MM/YYYY HH:mm:ss")
          
        });
      }
      return detail;
    },
  },
  mounted() {
    this.checkPath();
  },
  created() {
    this.checkPath();
  },
  watch: {
    "$route.name": "checkPath",
  },
  methods: {
    formatDate(date) {
      let result = date.split(' ')[0];
      return result
    },
    isOverDue(item) {
      if (item.status == 'A' && item.received_at !== '-' && item.dueDate !== '-') {
        return this.moment(item.received_at, 'DD/MM/YYYY HH:mm:ss').isAfter(this.moment(item.dueDate, 'DD/MM/YYYY HH:mm:ss'));
      } 
      return false;
    },
    getDueDateClass(item) {
    const dueDate = this.moment(item.dueDate, 'DD/MM/YYYY HH:mm:ss')
    const currentDate = this.moment()
      if (item.status === 'P' && currentDate.isAfter(dueDate)) {
        return 'text-danger'
      }
        return ''
  } ,
    checkStatus(item) {
      let today = moment();
      let dueDate = moment(item.dueDate, "DD/MM/YYYY HH:mm:ss");

      if (item.status === "P") {
        if (today.isAfter(dueDate)) {
          return "text-orange";
        } else {
          return "text-primary";
        }
      } else if (item.status === "A") {
        return "text-success";
      } else if (item.status === "C") {
        return "text-danger";
      } else {
        return "";
      }
    },
    calculateProgress(item) {
      if (item.totalitemquantity === 0) return 0;
      return (item.totalitemQTYReceived / item.totalitemquantity) * 100;
    },
    getStock(page = 1) {
      if (this.transferIn) {
        this.getStockIN(page);
      } else if (this.transferOut) {
        this.getStockOUT(page);
      }
    },
    checkPath() {
      if (this.$route.name === "TransferIn") {
        this.transferIn = true;
        this.transferOut = false;
        this.getStockIN();
      } else if (this.$route.name === "TransferOut") {
        this.transferOut = true;
        this.transferIn = false;
        this.getStockOUT();
      }
    },
    getStockIN(page = 1) {
      this.typeDocument = "IN";
      this.loadingButton = false;
      const uid = `${localStorage.getItem("shopsUid")}`;
      const shopObjectId = this.$store.getters.shopObjectId;
      let params = {
        shopObjectId: shopObjectId,
        type: 4,
        page: page,
        limit: 50,
      };
      const headers = { shopObjectId: shopObjectId }
      if (this.keyword != "") {
        params = {
          ...params,
          searchVal: this.keyword,
        };
      } else {
        params = {
          ...params,
          startAt: moment(String(this.date.start)).format("YYYY-MM-DD"),
          endAt: moment(String(this.date.end)).format("YYYY-MM-DD"),
        };
      }

      if (this.statusDoc != "") {
        params = {
          ...params,
          status: this.statusDoc,
        };
      }

      this.loading = true;
      axios({
        method: "get",
        url: "/api/v1.0/" + uid + "/stockdocument/transfer",
        params: params,
        headers: headers
      })
        .then((res) => {
          if (res.data.paginate.items == undefined) {
            this.meta_data.items = 0;
          } else {
            this.meta_data.items = res.data.paginate.items;
          }
          this.meta_data.last_page = res.data.paginate.pageCount;
          this.meta_data.current_page = res.data.paginate.currentPage;
          this.meta_data.itemCount = res.data.paginate.itemCount;
          this.meta_data.limit = res.data.paginate.perPage;
          this.data = res.data.data;
          this.loading = false;
          this.loadingButton = true;
          this.datastatus = "IN";
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getStockOUT(page = 1) {
      this.typeDocument = "OUT";
      this.loadingButton = false;
      const uid = `${localStorage.getItem("shopsUid")}`;
      const shopObjectId = this.$store.getters.shopObjectId;
      let params = {
        shopObjectId: shopObjectId,
        type: 3,
        page: page,
        limit: 50,
      };
      const headers = { shopObjectId: shopObjectId }
      if (this.keyword != "") {
        params = {
          ...params,
          searchVal: this.keyword,
        };
      } else {
        params = {
          ...params,
          startAt: moment(String(this.date.start)).format("YYYY-MM-DD"),
          endAt: moment(String(this.date.end)).format("YYYY-MM-DD"),
        };
      }

      if (this.statusDoc != "") {
        params = {
          ...params,
          status: this.statusDoc,
        };
      }

      this.loading = true;
      axios({
        method: "get",
        url: "/api/v1.0/" + uid + "/stockdocument/transfer",
        params: params,
        headers: headers
      })
        .then((res) => {
          if (res.data.paginate.items == undefined) {
            this.meta_data.items = 0;
          } else {
            this.meta_data.items = res.data.paginate.items;
          }
          this.meta_data.last_page = res.data.paginate.pageCount;
          this.meta_data.current_page = res.data.paginate.currentPage;
          this.meta_data.itemCount = res.data.paginate.itemCount;
          this.meta_data.limit = res.data.paginate.perPage;
          this.data = res.data.data;
          this.loading = false;
          this.loadingButton = true;
          this.datastatus = "OUT";
        })
        .catch((error) => {
          console.log(error);
        });
    },

    transferOutDetail(item) {
      this.$router.push("/inventory/transfer/transferOutDetail/" + item.objectId);
    },
  },
};
</script>

<style src="../../../assets/styles/inventory.css" scoped></style>

